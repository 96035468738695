import { createContext, useContext, useState, lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { authProvider } from "./auth";
import { socketConnectAuth } from "../api/socket";

const Drawer = lazy(() => import("components/drawer"));
const NotFound = lazy(() => import("pages/notFound"));

const Login = lazy(() => import("pages/login"));

const Dashboard = lazy(() => import("pages/dash"));

const Help = lazy(() => import("pages/help"));

const KanbanTicket = lazy(() => import("pages/kanban/ticket"));
const KanbanList = lazy(() => import("pages/kanban/list"));
const UserConfig = lazy(() => import("pages/config/user"));
const WhatsappConfig = lazy(() => import("pages/config/whatsapp"));
const TabConfig = lazy(() => import("pages/config/tab"));
const BotConfig = lazy(() => import("pages/config/bot"));
const CompanyConfig = lazy(() => import("pages/config/company"));
const Config = lazy(() => import("pages/config"));
const WorkDay = lazy(() => import("pages/config/workDay"));
const Team = lazy(() => import("pages/config/team"));

let AuthContext = createContext({});

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

function AuthProvider({ children }) {
  let [login, setLogin] = useState(false);
  let navigate = useNavigate();
  let signin = (login, callback) => {
    return authProvider.signin(login, (data) => {
      if (data) {
        setLogin(data);
        if (!data.error && !data.first_login) {
          localStorage.setItem("token", data.token);
          navigate("/", { replace: true });
        }
      }
      callback && callback();
    });
  };

  let signout = (callback) => {
    return authProvider.signout(() => {
      setLogin(null);
      localStorage.removeItem("token");
      callback && callback();
    });
  };
  let firstLogin = (login, callback) => {
    return authProvider.firstLogin(login, (data) => {
      if (data) {
        if (!data.error) {
          setLogin(data);
          localStorage.setItem("token", data.token);
          navigate("/", { replace: true });
        } else {
          setLogin((prevState) => ({ ...prevState, ...data }));
        }
      }
      callback && callback();
    });
  };
  let forgotPassword = (login, callback) => {
    return authProvider.forgotPassword(login, (data) => {
      callback && callback(data);
    });
  };
  let checkAuth = () => {
    return authProvider.checkAuth((data) => {
      setLogin(data);
    });
  };

  let value = { login, signin, signout, firstLogin, checkAuth, forgotPassword };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function RequireAuth({ children, role }) {
  const auth = useAuth();
  const location = useLocation();

  if (!localStorage.getItem("token") || (auth.login && auth.login.error)) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (auth?.login?.role === "2" && !location.pathname.includes("bot")) {
    return <Navigate to="/configuracoes/bot" state={{ from: location }} />;
  }
  socketConnectAuth();
  return (
    <Suspense fallback={<>...</>}>
      <Drawer>
        {!role || role === Number(auth.login?.role) ? children : <NotFound />}
      </Drawer>
    </Suspense>
  );
}

const RoutesComponent = () => {
  const auth = useAuth();
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <></>
            </RequireAuth>
          }
        />

        <Route
          path="/login"
          element={
            <Suspense fallback={<>...</>}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Suspense fallback={<>...</>}>
                <Dashboard />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="/ajuda"
          element={
            <RequireAuth>
              <Suspense fallback={<>...</>}>
                <Help />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route path="/configuracoes">
          <Route
            path=""
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <Config />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="whatsapp"
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <WhatsappConfig />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="usuario"
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <UserConfig />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="tab"
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <TabConfig />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="bot"
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <BotConfig />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="company"
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <CompanyConfig />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="workday"
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <WorkDay />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="team"
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <Team />
                </Suspense>
              </RequireAuth>
            }
          />
        </Route>

        <Route path="/atendimento/:workspace">
          <Route
            path=""
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <KanbanList />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path=":queue"
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <KanbanList />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path=":queue/:ticket"
            element={
              <RequireAuth>
                <Suspense fallback={<>...</>}>
                  <KanbanTicket />
                </Suspense>
              </RequireAuth>
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <RequireAuth>
              <Suspense fallback={<>...</>}>
                <NotFound />
              </Suspense>
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

export default RoutesComponent;
