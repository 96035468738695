import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translate/en.json";
import translationPT from "./translate/pt.json";
import translationES from "./translate/es.json";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: translationEN,
  },
  pt: {
    translation: translationPT,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "pt",
    debug: true,
    resources,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
