import {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {createGlobalStyle} from 'styled-components'
import {normalize} from 'styled-normalize'
import Routes from './routes'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GenerateManifest from "./generateManifest";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export const GlobalStyle = createGlobalStyle`
  :root {
    --rs-primary-50: #ffedf5!important;
    --rs-primary-100: #fac0d7!important;
    --rs-primary-200: #f595ba!important;
    --rs-primary-300: #f26d9e!important;
    --rs-primary-400: #ed4580!important;
    --rs-primary-500: #ec407a!important;
    --rs-primary-600: #de1655!important;
    --rs-primary-700: #c90e46!important;
    --rs-primary-800: #ab0735!important;
    --rs-primary-900: #820124!important;

    // Spectrum
    --rs-color-red: #f44336;
    --rs-color-orange: #fa8900;
    --rs-color-yellow: #ffb300;
    --rs-color-green: #4caf50;
    --rs-color-cyan: #00bcd4;
    --rs-color-blue: #ec407a;
    --rs-color-violet: #673ab7;
  }
  
  ${normalize}
  
  *, *:after, *:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  @media print {
    @page {
      size: 100mm 150mm;
    }
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
  }

`
const theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto',
            'DM Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#07c7c0',
            dark: '#07c7c0',
            light: '#07c7c0',
        },
        secondary: {
            main: '#ec407a',
            dark: '#ec407a',
            light: '#ec407a',
        },
    },
});

function App() {

    useEffect(() => {
        GenerateManifest({
            pathname: 'comtexto',
            name: "Comtexto",
            url: window.location.origin,
            shortname: "Comtexto",
            color: {
                primary: "#07c7c0",
                secondary: "#ec407a"
            }
        })

    }, [])

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
            <GlobalStyle/>
            <ToastContainer position="bottom-right"
                            autoClose={2000}
                            theme="colored"/>
            <BrowserRouter>
                <Routes/>
            </BrowserRouter>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
