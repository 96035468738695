import axios from 'axios'
import {env} from 'env'

const instance = axios.create({
    baseURL: `${env.REACT_APP_API_URL}/api`,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
});

instance.interceptors.request.use (
    config => {
      const token = localStorage.getItem('token')
      if (token) config.headers['authorization'] = `Bearer ${token}`;
      return config;
    },
    error =>  Promise.reject (error)
)

export default instance