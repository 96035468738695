import io from 'socket.io-client'

import {env} from 'env'
const socket = io.connect(`${env.REACT_APP_API_URL}`, {
    reconnection: true,
    autoConnect: false,
    timeout: 2000,
    reconnectionDelay: 1000,
    reconnectionDelayMax : 5000,
    reconnectionAttempts: Infinity,
});

export const socketConnectAuth = () => {
    socket.connected && socket.disconnect()
    socket.io.opts.query = {
        token: localStorage.getItem('token'),
    }
    socket.connect()
};

export default socket
