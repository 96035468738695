export default async (x) => {
    const x1 = await require(`./assets/icons/${x.pathname}/icon_x1.png`);
    const x48 = await require(`./assets/icons/${x.pathname}/icon_x48.png`);
    const x72 = await require(`./assets/icons/${x.pathname}/icon_x72.png`);
    const x96 = await require(`./assets/icons/${x.pathname}/icon_x96.png`);
    const x128 = await require(`./assets/icons/${x.pathname}/icon_x128.png`);
    const x144 = await require(`./assets/icons/${x.pathname}/icon_x144.png`);
    const x192 = await require(`./assets/icons/${x.pathname}/icon_x192.png`);
    const x384 = await require(`./assets/icons/${x.pathname}/icon_x384.png`);
    const x512 = await require(`./assets/icons/${x.pathname}/icon_x512.png`);

    const dynamicManifest = {
        "name": x.name,
        "short_name": x.shortname,
        "icons": [
            {
                "src": x48,
                "type": "image/png",
                "sizes": "48x48",
                "purpose": "any"
            },
            {
                "src": x72,
                "type": "image/png",
                "sizes": "72x72",
                "purpose": "any"
            },
            {
                "src": x96,
                "type": "image/png",
                "sizes": "96x96",
                "purpose": "any"
            },
            {
                "src": x128,
                "type": "image/png",
                "sizes": "128x128",
                "purpose": "any"
            },
            {
                "src": x144,
                "type": "image/png",
                "sizes": "144x144",
                "purpose": "any"
            },
            {
                "src": x192,
                "type": "image/png",
                "sizes": "192x192",
                "purpose": "any"
            },
            {
                "src": x384,
                "type": "image/png",
                "sizes": "384x384",
                "purpose": "any"
            },
            {
                "src": x512,
                "type": "image/png",
                "sizes": "512x512",
                "purpose": "any maskable"
            }
        ],
        "start_url": x.url,
        "scope": x.url,
        "display": "standalone",
        "theme_color": "#014011",
        "background_color": "#ffffff"
    }

    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);

    document.querySelector('[rel="manifest"]').setAttribute('href', manifestURL);
    document.querySelector('[rel="apple-touch-icon"]').setAttribute('href', x384);
}
