import instance from './instance'

export function login(data) {
    return instance.post('/authentication', data)
}

export function forgot(data) {
    return instance.post('/authentication/forgot_password', data)
}

export function first_login(data) {
    return instance.post('/authentication/first_login', data)
}

export function me() {
    return instance.get('/authentication/me')
}

export function logout() {
    return instance.get('/authentication/logout')
}