import {first_login, forgot, login, logout, me} from "../api/auth";
//import {socketConnectAuth} from "../api/socket";

const authProvider = {
    signin: async (data, callback) => {
        try {
            const response = await login(data)
            callback && setTimeout(() => callback(response.data), 100)
        } catch (error) {
            callback && setTimeout(() => callback(error?.response?.data ?? {error: 'Error ao tentar logar na api'}), 100)
        }
    },
    signout: async (callback) => {
        if (localStorage.getItem('token')) {
            await logout()
        }
        callback && setTimeout(callback, 100)
    },
    firstLogin: async (data,callback) => {
      try {
          const response = await first_login(data)
          callback && setTimeout(() => callback(response.data), 100)
      } catch (error) {
          callback && setTimeout(() => callback(error.response.data), 100)
      }
    },
    forgotPassword: async (data,callback) => {
      try {
          const response = await forgot(data)
          callback && setTimeout(() => callback(response.data), 100)
      } catch (error) {
          callback && setTimeout(() => callback(error.response.data), 100)
      }
    },
    checkAuth: async (callback) => {
        try {
            const response = await me()
            //socketConnectAuth()
            callback && setTimeout(() => callback(response.data), 100)
        } catch (error) {
            localStorage.removeItem('token')
            callback && setTimeout(() => callback(error?.response?.data), 100)
        }
    }
};

export { authProvider };
